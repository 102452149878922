<template>
  <v-dialog
    v-model="dialog"
    width="100%"
    height="100%"
    @click:outside="dialog = false"
    style="z-index: 999 !important"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn v-bind="attrs" v-on="on" tile depressed large color="#d7c194">
        {{ $store.getters.getFilterCatById(10).title }}
      </v-btn>
    </template>
    <div style="background-color: #f5f6fa" class="ma-0 pa-0">
      <div class="d-flex justify-end w-100 dialog-button">
        <v-btn
          class="ma-2"
          tile
          depressed
          large
          color="#d7c194"
          @click="dialog = false"
        >
          {{ $t("close") }}
          <v-icon dark color="#000" class="ml-3">mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
    <v-row
      style="background-color: #f5f6fa"
      class="d-flex align-center justify-center mb-0 pb-0"
    >
      <v-col class="mb-0 pb-0" cols="12" md="4" v-for="product in products" :key="product.id">
        <v-card
          color="#191C21"
          style="border: 3px solid #d7c194"
          height="600px"
        >
          <v-container>
            <v-row>
              <v-col>
                <v-img :src="product.image_url" height="250px" contain> </v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="10">
                    <h2 class="white--text">{{ product.title }}</h2>
                    <h3 style="color: #d7c194">{{ product.price_bottle }}</h3>
                  </v-col>
<!--                  <v-col cols="2">-->
<!--                    <v-icon x-large color="#d7c194">mdi-chevron-right</v-icon>-->
<!--                  </v-col>-->
                </v-row>
                <v-row>
                  <v-col>
                    <p class="white--text">{{ product.description }}</p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <div class="d-flex align-center justify-center mt-2">
          <v-btn
            tile
            depressed
            fab
            large
            color="transparent"
            @click.stop="addToFav(product)"
          >
            <v-icon dark color="rgba(0, 0, 0, .6)" v-if="isFav(product.id)">
              mdi-check
            </v-icon>
            <v-icon dark color="rgba(0, 0, 0, .6)" v-else>
              mdi-checkbox-marked
            </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script>
export default {
  name: "CombinedProductsDialog",
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    async addToFav(obj) {
      let arr = this.$store.getters.favIds;
      let index = arr.indexOf(obj.id);

      if (index > -1) {
        arr.splice(index, 1);
      } else {
        arr.push(obj.id);
      }
      if (this.isFav(obj.id)) {
        await this.$store.dispatch("removeFromFav", { product_id: obj.id });
      } else {
        await this.$store.dispatch("addToFav", { product_id: obj.id });
      }
    },
    isFav(id) {
      if (this.$store.getters.favs.some((item) => item.id === id)) {
        return false;
      }
      return true;
    },
  },
  data: () => {
    return {
      dialog: false,
    };
  },
};
</script>

<style scoped></style>
