<template>
  <div>
    <div
      v-if="current_ads && showAds"
      class="home-overlay flex-center"
      @click="closeAd()"
      ref="overlay"
    >
      <v-img
        v-if="!current_ads.image_url == false"
        :src="current_ads.image_url"
        :alt="current_ads.title"
        class="big-ad-full"
        contain
      ></v-img>
      <div v-else style="height: auto; width: 100%">
        <v-img
          v-if="current_ads.video_url.substr(-4) === 'webp'"
          :src="current_ads.video_url"
        ></v-img>
        <div v-else>
          <video
            v-if="length_ads > 1"
            @ended="endVideo()"
            id="myVideo"
            autoplay
            height="100%"
            width="100%"
            :src="current_ads.video_url"
          ></video>
          <video
            v-else
            autoplay
            height="100%"
            width="100%"
            :src="current_ads.video_url"
            loop
          ></video>
        </div>
        <!--        <video v-else autoplay height="100%" width="100%" :src="current_ads.video_url" loop></video>-->
      </div>
    </div>
    <div v-else>
      <div
        v-if="current && client.menu_countries.length"
        class="client flex-center flex-column"
      >
        <v-container fluid class="bacve-cover py-8 px-0"> </v-container>
        <template>
          <div class="header w-100 flex-center py-8">
            <v-container
              class="d-flex justify-space-between align-items-center"
            >
              <div>
                <v-btn
                  v-if="!saved"
                  color="#272c33"
                  depressed
                  height="56"
                  dark
                  class="main-btn pl-2 mr-4 mb-4"
                  @click="winesBack"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                  {{ $t("back") }}
                </v-btn>
                <v-btn
                  color="#272c33"
                  depressed
                  height="50"
                  dark
                  class="main-btn pl-2 mr-4 mb-4"
                  v-else
                  @click="toggleCover('ponovo postavi')"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                  {{ $t("back") }}
                </v-btn>
              </div>
              <div
                class=""
                style="margin-left: auto; margin-right: auto"
                v-if="client.has_glass"
              >
                <v-btn
                  class="main-btn mr-4 mb-4 text-none"
                  height="72"
                  min-width="150"
                  tyle="border: 1px solid #d7c194 !important"
                  depressed
                  dark
                  color="#272c33"
                  @click="glass = !glass"
                >
                  <v-icon dark size="38" color="#d7c194" left
                    >mdi-glass-wine</v-icon
                  >
                  <!--                <v-checkbox style="height: 100% !important; width: 100% !important;" hide-details class="mt-0" color="#d7c194" v-model="glass" dark></v-checkbox>-->
                  <span style="font-size: 20px">{{ $t("on_glass") }}</span>
                  <v-icon dark color="green" right v-if="glass"
                    >mdi-check</v-icon
                  >
                </v-btn>
              </div>
            </v-container>
          </div>
          <!--        <v-container class="w-100 home-2 flex-center flex-column">-->
          <!--          <h2 class="w-100 text-center gold-text font-weight-light mb-7">Odaberite zemlju</h2>-->
          <!--          <v-row class="w-100 mx-0 flex-center">-->
          <!--            <v-col cols="4" class="flex-center px-5">-->
          <!--              <div class="menu-item2">-->
          <!--                <v-checkbox  hide-details class="mt-0" label="Samo na čašu" color="#d7c194" v-model="glass" dark></v-checkbox>-->
          <!--              </div>-->
          <!--            </v-col>-->
          <!--            <v-col v-for="(item, i) in client.menu_countries"-->
          <!--                   :key="i"-->
          <!--                   cols="4" class="flex-center px-5">-->
          <!--              <div class="menu-item1" @click="addFilters(item)">-->
          <!--                <v-icon size="50" color="rgba(255, 255, 255, .4)">mdi-flag</v-icon>-->
          <!--                <p class="mt-4 ml-4">{{ $store.getters.getCountryById(item).name }}</p>-->
          <!--              </div>-->
          <!--            </v-col>-->
          <!--            <v-col-->
          <!--                   cols="4" class="flex-center px-5">-->
          <!--              <div class="menu-item1" @click="addFilters(null)">-->
          <!--                <v-icon size="50" color="rgba(255, 255, 255, .4)">mdi-flag</v-icon>-->
          <!--                <p class="mt-4 ml-4">Sve zemlje</p>-->
          <!--              </div>-->
          <!--            </v-col>-->
          <!--          </v-row>-->
          <!--        </v-container>-->
          <v-container ref="myCarousel" fluid class="px-0 wines-3">
            <div class="w-100 d-flex">
              <carousel-3d
                class="carousel"
                :controls-visible="true"
                :clickable="true"
                :autoplay="false"
                :perspective="0"
                :display="5"
                :scale="3"
                :space="450"
                border="0"
                style="margin-top: 70px"
              >
                <slide
                  v-for="(item, index) in client.menu_countries"
                  :key="index"
                  :index="index"
                  class="slide-cell"
                >
                  <transition fade>
                    <div
                      class="carousel-wrap2"
                      style="bottom: 500px !important"
                      @click="addFilters(item)"
                    >
                      <div class="image-wrap position-relative">
                        <v-img
                          :lazy-src="
                            $store.getters.getCountryById(item).shape_image_url
                          "
                          :src="
                            $store.getters.getCountryById(item).shape_image_url
                          "
                          alt="Bottle"
                          style="max-height: 300px"
                          class="cell-bottle"
                          contain
                        ></v-img>
                      </div>
                      <div class="carousel-text2 mt-2 px-2">
                        <h2 class="text-carousel w-100 text-center">
                          {{ $store.getters.getCountryById(item).name }}
                        </h2>
                        <h4 class="text-carousel-vine w-100 text-center">
                          {{ $t("wine") }}
                        </h4>
                      </div>
                    </div>
                  </transition>
                </slide>
                <slide :index="client.menu_countries.length" class="slide-cell">
                  <transition fade>
                    <div class="carousel-wrap2" @click="addFilters(null)">
                      <div class="image-wrap position-relative">
                        <v-img
                          :src="require('@/assets/img/all_wines.png')"
                          alt="Bottle"
                          style="max-height: 300px"
                          class="cell-bottle"
                          contain
                        ></v-img>
                      </div>
                      <div class="carousel-text2 mt-2 px-2">
                        <h2 class="text-carousel w-100 text-center">
                          {{ $t("all_wines") }}
                        </h2>
                      </div>
                    </div>
                  </transition>
                </slide>
                <slide
                  :index="client.menu_countries.length + 1"
                  class="slide-cell"
                >
                  <transition fade>
                    <div class="carousel-wrap2" @click="addFilters(null, true)">
                      <div class="image-wrap position-relative">
                        <v-img
                          :src="require('@/assets/img/preporuka.png')"
                          alt="Bottle"
                          style="max-height: 300px"
                          class="cell-bottle"
                          contain
                        ></v-img>
                      </div>
                      <div class="carousel-text2 mt-2 px-2">
                        <h2 class="text-carousel w-100 text-center">
                          {{ $t("preporuka") }}
                        </h2>
                      </div>
                    </div>
                  </transition>
                </slide>
                <!--              <slide :index="5" class="slide-cell">-->
                <!--                <transition fade>-->
                <!--                  <div class="carousel-wrap2" >-->
                <!--                    <div class="image-wrap position-relative" @click="glass = !glass">-->
                <!--                      <v-img :src="require('@/assets/img/na_casu.png')" alt="Bottle" style="max-height: 300px" class="cell-bottle" contain></v-img>-->
                <!--                    </div>-->
                <!--                    <div class="carousel-text mt-2 px-2">-->
                <!--                      <v-row align="center">-->
                <!--                        <v-col cols="2">-->
                <!--                          <v-checkbox style="height: 100% !important; width: 100% !important;" hide-details class="mt-0" color="#d7c194" v-model="glass" dark></v-checkbox>-->
                <!--                        </v-col>-->
                <!--                        <v-col cols="10">-->
                <!--                      <h2 class="text-carousel w-100 text-center" @click="glass = !glass">-->
                <!--                        {{$t('on_glass')}}-->
                <!--                      </h2>-->
                <!--                        </v-col>-->
                <!--                      </v-row>-->
                <!--                      </div>-->
                <!--                  </div>-->
                <!--                </transition>-->
                <!--              </slide>-->
              </carousel-3d>
            </div>
          </v-container>
        </template>
      </div>
      <div v-else>
        <div class="wines" v-if="client">
          <v-container fluid class="bacve-cover py-8 px-0"> </v-container>
          <div
            class="header w-100 flex-center py-8"
            style="z-index: 90 !important"
          >
            <v-container
              class="d-flex justify-space-between align-items-center"
            >
              <div v-if="client.menu_countries.length">
                <v-btn
                  v-if="!saved"
                  color="#272c33"
                  depressed
                  height="56"
                  dark
                  class="main-btn pl-2 mr-4 mb-4"
                  @click="winesBackToFilters()"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                  {{ $t("back") }}
                </v-btn>
                <v-btn
                  color="#272c33"
                  depressed
                  height="50"
                  dark
                  class="main-btn pl-2 mr-4 mb-4"
                  v-else
                  @click="toggleCover('ponovo postavi')"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                  {{ $t("back") }}
                </v-btn>
              </div>
              <div v-else>
                <v-btn
                  v-if="!saved"
                  color="#272c33"
                  depressed
                  height="56"
                  dark
                  class="main-btn pl-2 mr-4 mb-4"
                  @click="winesBack"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                  {{ $t("back") }}
                </v-btn>
                <v-btn
                  color="#272c33"
                  depressed
                  height="50"
                  dark
                  class="main-btn pl-2 mr-4 mb-4"
                  v-else
                  @click="toggleCover('ponovo postavi')"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                  {{ $t("back") }}
                </v-btn>
              </div>
              <div
                v-if="!saved"
                style="display: flex;"
              >
                <v-text-field
                  v-model="search"
                  dark
                  height="50"
                  style="width: 220px"
                  background-color="#272c33"
                  hide-details
                  outlined
                  color="#d7c194"
                  :label="$t('search')"
                  class="custom-field"
                >
                  <v-icon slot="append" color="#d7c194">mdi-magnify</v-icon>
                </v-text-field>
                <v-menu
                  transition="slide-y-transition"
                  v-model="menu1"
                  bottom
                  offset-y
                  min-width="200"
                  persistent
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="main-btn ml-4 mb-4 text-none"
                      height="56"
                      depressed
                      light
                      color="#d7c194"
                      v-bind:class="{ buttonActive: saved }"
                      v-on="on"
                      @click="menu1 = true"
                    >
                      <v-icon left>mdi-menu</v-icon>
                      {{ $t("menu1") }}
                      <!--                    Menu-->
                    </v-btn>
                  </template>
                  <v-list dark>
                    <v-list-item-group>
                      <v-list-item
                        v-for="type in $store.getters.getProductTypes"
                        v-if="grouped_products[type.id]"
                        :key="type.title"
                        :to="'/products/' + type.id"
                      >
                        <v-list-item-icon>
                          <v-icon v-text="type.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          <h3 class="font-weight-regular">
                            {{ type.title }}
                          </h3>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </div>
              <div class="mr-5">
                <template v-if="client.qr_orders_active">
                  <v-badge
                      v-if="$store.getters.favs.length > 0"
                      avatar
                      class="black--text"
                      color="white"
                      overlap
                  >
                    <template v-slot:badge>
                      <v-avatar>
                      <span style="color: black !important">{{
                          $store.getters.favs.length
                        }}</span>
                      </v-avatar>
                    </template>
                    <v-btn
                        class="main-btn mb-4"
                        height="56"
                        depressed
                        dark
                        color="#272c33"
                        v-bind:class="{ buttonActive: saved }"
                        to="/cart"
                        style="margin-left: -50px !important"
                    >
                      <!--                @click="toggleSaved()"-->
                      <v-icon dark color="white">mdi-checkbox-marked</v-icon>
                    </v-btn>
                  </v-badge>
                  <v-btn
                      v-else
                      class="main-btn mb-4"
                      height="56"
                      depressed
                      dark
                      color="#272c33"
                      v-bind:class="{ buttonActive: saved }"
                      to="/cart"
                      style="margin-left: -50px !important"
                  >
                    <!--                @click="toggleSaved()"-->
                    <v-icon dark color="white">mdi-check</v-icon>
                  </v-btn>
                </template>

                <v-btn
                  v-if="saved"
                  color="#d7c194"
                  depressed
                  class="main-btn mr-5 mb-4"
                  height="56"
                  @click.stop="openOrderDialog"
                >
                  <!--                <v-icon class="mr-2">mdi-filter-variant</v-icon>-->
                  {{ $t("order") }}
                </v-btn>

                <v-menu
                  transition="slide-y-transition"
                  v-model="menu"
                  bottom
                  offset-y
                  min-width="300"
                  persistent
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="#d7c194"
                      depressed
                      class="main-btn mr-2 mb-4 ml-4"
                      height="56"
                      v-on="on"
                      @click="menu = true"
                      style="margin-left: 10px !important"
                    >
                      <v-icon class="mr-2">mdi-filter-variant</v-icon>
                      {{ $t("filters") }}
                    </v-btn>
                  </template>
                  <v-list dark class="filter-menu">
                    <v-list-item>
                      <v-list-item-title>
                        <h2 class="font-weight-regular">
                          {{ $t("filters") }}
                        </h2>
                      </v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item v-if="!client.menu_countries.length">
                      <div class="flex-center flex-column w-100">
                        <v-list-item-title class="w-100 py-5">
                          <h3 class="white--secondary font-weight-regular">
                            {{ $t("serving") }}
                          </h3>
                        </v-list-item-title>
                        <v-list-item-title class="w-100">
                          <v-checkbox
                            :label="$t('glass')"
                            v-model="glass"
                            color="#d7c194"
                            class="ma-0 filter-check w-100"
                          ></v-checkbox>
                        </v-list-item-title>
                      </div>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <div class="flex-center flex-column w-100">
                        <v-list-item-title class="w-100 py-1">
                          <h3 class="white--secondary font-weight-regular">
                            {{ $t("price") }}
                          </h3>
                        </v-list-item-title>
                      </div>
                    </v-list-item>
                    <v-row justify="center" align="center" class="pa-0 ma-0">
                      <!--                    <v-col cols="12">-->
                      <!--                      <v-range-slider-->
                      <!--                          height="70"-->
                      <!--                          hide-details-->
                      <!--                          thumb-label="always"-->
                      <!--                          label="PRICE:"-->
                      <!--                          step="10"-->
                      <!--                      ></v-range-slider>-->
                      <!--                    </v-col>-->
                      <v-col cols="4">
                        <v-btn
                          :color="type_price === 1 ? '#A4906A' : '#d7c194'"
                          width="100%"
                          light
                          @click="
                            type_price = 1;
                            filterProducts();
                            menu = false;
                          "
                          >0-100
                        </v-btn>
                      </v-col>
                      <v-col cols="4">
                        <v-btn
                          :color="type_price === 2 ? '#A4906A' : '#d7c194'"
                          width="100%"
                          light
                          @click="
                            type_price = 2;
                            filterProducts();
                            menu = false;
                          "
                          >101-300
                        </v-btn>
                      </v-col>
                      <v-col cols="4">
                        <v-btn
                          :color="type_price === 3 ? '#A4906A' : '#d7c194'"
                          width="100%"
                          light
                          @click="
                            type_price = 3;
                            filterProducts();
                            menu = false;
                          "
                          >300+
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <div
                      v-for="(cat, index) in filterCategories"
                      v-if="cat.visible == 1 && cat.product_type_id == 1"
                      :key="index"
                    >
                      <v-list-item>
                        <div class="flex-center flex-column w-100">
                          <v-list-item-title class="w-100 py-5">
                            <h3 class="white--secondary font-weight-regular">
                              {{ cat.title }}
                            </h3>
                          </v-list-item-title>
                          <v-list-item-title
                            v-for="(item, index) in getAvailableFilters(cat.id)"
                            :key="index"
                            class="w-100"
                          >
                            <v-checkbox
                              :label="item.title"
                              v-model="newFilters[item.id]"
                              @change="toggleFilter(item.id, cat.id, $event)"
                              color="#d7c194"
                              class="ma-0 filter-check w-100"
                            ></v-checkbox>
                          </v-list-item-title>
                        </div>
                      </v-list-item>
                      <v-divider></v-divider>
                    </div>
                    <v-list-item>
                      <div class="flex-center flex-column w-100">
                        <v-list-item-title class="w-100 py-5">
                          <h3 class="white--secondary font-weight-regular">
                            {{ $t("regions") }}
                          </h3>
                        </v-list-item-title>
                        <v-list-item-title
                          class="w-100"
                          v-for="(region, index) in regions"
                          v-if="client.wine_regions.includes(region.id)"
                          :key="index"
                        >
                          <v-checkbox
                            :label="region.name"
                            v-model="selectedRegionsModels[region.id]"
                            @change="filterRegion(region.id, $event)"
                            color="#d7c194"
                            class="ma-0 filter-check w-100"
                          ></v-checkbox>
                        </v-list-item-title>
                      </div>
                    </v-list-item>
                  </v-list>
                  <v-btn
                    class="w-100 main-btn text--primary filterBtn"
                    depressed
                    tile
                    color="#d7c194"
                    @click="
                      filterProducts();
                      menu = false;
                    "
                    >{{ $t("confirm") }}
                  </v-btn>
                  <v-btn
                    class="w-100 main-btn text--primary filterBtn"
                    depressed
                    tile
                    color="#212121"
                    @click="
                      clearFilters();
                      menu = false;
                    "
                  >
                    <span class="white--text">{{ $t("clear") }}</span>
                  </v-btn>
                </v-menu>
                <v-btn
                  color="#272c33"
                  depressed
                  v-bind:class="{ buttonActive: cover }"
                  dark
                  class="main-btn mr-2 mb-4"
                  height="56"
                  @click="toggleCover()"
                >
                  <ui-cover v-if="cover"></ui-cover>
                  <gen-list v-else></gen-list>
                </v-btn>
                <!--              <v-btn color="#272c33" depressed v-bind:class="{ buttonActive: list }" dark-->
                <!--                     class="main-btn mr-2 mb-4" height="56" @click="toggleList()">-->
                <!--                <gen-list></gen-list>-->
                <!--              </v-btn>-->
                <!--              <v-btn color="#272c33" depressed v-bind:class="{ buttonActive: grid }" dark
                                     class="main-btn mr-2 mb-4" height="56" @click="toggleGrid()">
                                <ui-tiles></ui-tiles>
                              </v-btn>-->
              </div>
            </v-container>
          </div>
          <v-container
            class="wines-2 products-2"
            ref="wineDisplay"
            v-if="
              (!saved && wines.length > 0) ||
              (saved && this.$store.getters.favs.length > 0)
            "
            v-bind:class="{ closed: cover }"
          >
            <div v-if="list" class="mt-7">
              <div
                v-for="(wine) in wines"
                :key="wine.id+'_listItem'"
                @click="openBottle(wine)"
              >
                <list-item :data="wine"></list-item>
              </div>
            </div>
            <!--          <div v-if="grid || (cover && wines.length <= 4)">
                        <v-row class="w-100 d-flex justify-center mx-0">
                          <v-col md="4" cols="12" class="d-flex justify-center align-center w-100 grid-col"
                                 v-for="(wine, index) in wines" :key="index" @click="openBottle(wine)">
                            <grid-item :data="wine" class="w-100"></grid-item>
                          </v-col>
                        </v-row>
                      </div>-->
            <div v-if="saved">
              <v-row
                class="w-100 d-flex justify-center mx-0"
                style="margin-top: 50px"
              >
                <v-col
                  md="4"
                  cols="12"
                  class="d-flex justify-center align-center w-100 grid-col"
                  v-for="(wine, index) in $store.getters.favs"
                  :key="index"
                  @click="openBottle(wine)"
                >
                  <grid-item :data="wine" class="w-100"></grid-item>
                </v-col>
              </v-row>
            </div>
          </v-container>
          <v-container ref="myCarousel" v-if="cover" fluid class="px-0 wines-3">
            <div class="w-100 d-flex">
              <!--            <div id="winesSwiper" class="w-100 swiper-container">
                            <div class="swiper-wrapper">
                              <div v-for="wine in virtualData.slides"
                                   :style="slideStyles"
                                   class="swiper-slide">
                                <div @click="openBottle(wine)" class="position-relative w-100">
                                  <div class="carousel-div"
                                       :class="{ highlighted: wine.promotion == 1 }">
                                    <v-btn class="ma-2 list-button" tile depressed fab dark small color="black"
                                           @click.stop="addToFav(wine)">
                                      <v-icon dark color="rgba(255, 255, 255, .6)" v-if="isFav(wine.id)">
                                        mdi-cart-outline
                                      </v-icon>
                                      <v-icon dark color="rgba(255, 255, 255, .6)" v-else>
                                        mdi-cart
                                      </v-icon>
                                    </v-btn>
                                  </div>
                                  <div class="image-wrap position-relative ">
                                    <img :src="wine.image_url" alt="Bottle" class="cell-bottle">
                                    &lt;!&ndash;                        <div class="swiper-lazy-preloader"></div>&ndash;&gt;
                                  </div>
                                  <div class="carousel-text mt-2 px-2">
                                    <h2 class="white&#45;&#45;text w-100 text-center">
                                      {{ wine.title }} {{ wine.id }}
                                    </h2>
                                    <h4 v-if="wine.winery" class="white&#45;&#45;text w-100 text-center" style="opacity: 37% !important;">
                                      {{ wine.winery.name }}
                                    </h4>
                                    <div class="prices d-flex mb-5 align-center">
                                      <div class="d-flex justify-center align-center mr-3">
                                        <v-icon color="#d7c194" large>mdi-bottle-wine</v-icon>
                                        <span class="gold-text">{{ wine.price_bottle }}{{ client.currency }}</span>
                                      </div>
                                      <template v-if="wine.price_glass">
                                        <span class="gold-text">-</span>
                                        <div class="d-flex justify-center align-center ml-3">
                                          <v-icon color="#d7c194" large>mdi-glass-wine</v-icon>
                                          <span class="gold-text">{{ wine.price_glass }}{{ client.currency }}</span>
                                        </div>
                                      </template>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>-->
              <carousel-3d
                class="w-100 carousel"
                :controls-visible="true"
                :clickable="true"
                :autoplay="false"
                :perspective="0"
                :display="5"
                :scale="3"
                :space="450"
                border="0"
              >
                <slide
                  v-for="(wine, index) in wines"
                  :key="wine.id + '_slide'"
                  :index="index"
                  class="slide-cell"
                  style="min-width: 360px; width: fit-content"
                >
                  <transition fade>
                    <div @click="openBottle(wine)" class="carousel-wrap">
                      <div
                        class="carousel-div"
                        :class="{ highlighted: wine.promotion == 1 }"
                      >
                        <v-btn
                          class="ma-2 list-button"
                          tile
                          depressed
                          fab
                          dark
                          small
                          color="black"
                          v-if="client.qr_orders_active"
                          @click.stop="addToFav(wine)"
                        >
                          <v-icon dark color="white" v-if="isFav(wine.id)">
                            mdi-check
                          </v-icon>
                          <v-icon dark color="white" v-else>
                            mdi-checkbox-marked
                          </v-icon>
                        </v-btn>
                      </div>
                      <div
                        class="image-wrap position-relative"
                        @click="openBottle(wine)"
                      >
                        <img
                          :lazy-src="wine.image_url"
                          :src="wine.image_url"
                          alt="Bottle"
                          class="cell-bottle"
                        />
                      </div>
                      <div class="carousel-text mt-2 px-2">
                        <h2
                          v-if="wine.title.length < 30"
                          class="white--text w-100 text-center"
                        >
                          {{ wine.title }}
                        </h2>
                        <h4 v-else class="white--text w-100 text-center">
                          {{ wine.title }}
                        </h4>
                        <h4
                          v-if="wine.winery"
                          class="white--text w-100 text-center"
                          style="opacity: 37% !important"
                        >
                          {{ wine.winery.name }}
                        </h4>
                        <div class="prices d-flex mb-5 align-center">
                          <template v-if="wine.price_magnum">
                            <div
                              class="d-flex justify-center align-center mr-2"
                            >
                              <v-icon color="#d7c194" size="56"
                                >mdi-bottle-wine</v-icon
                              >
                              <span class="gold-text">
                                {{ wine.price_magnum }}{{ client.currency }}
                                <template v-if="conversionRate">
                                  <br />
                                  {{ calculatePrice(wine.price_magnum)
                                  }}{{ "kn" }}
                                </template>
                              </span>
                            </div>
                          </template>
                          <template v-if="wine.price_bottle">
                            <span class="gold-text" v-if="wine.price_magnum"
                              >-</span
                            >
                            <div
                              class="d-flex justify-center align-center ml-1 mr-2"
                            >
                              <v-icon color="#d7c194" size="46"
                                >mdi-bottle-wine</v-icon
                              >
                              <span class="gold-text">
                                {{ wine.price_bottle }}{{ client.currency }}
                                <template v-if="conversionRate">
                                  <br />
                                  {{ calculatePrice(wine.price_bottle)
                                  }}{{ "kn" }}
                                </template>
                              </span>
                            </div>
                          </template>
                          <template v-if="wine.price_glass">
                            <span class="gold-text" v-if="wine.price_bottle"
                              >-</span
                            >
                            <div
                              class="d-flex justify-center align-center ml-1"
                            >
                              <v-icon color="#d7c194" size="36"
                                >mdi-glass-wine</v-icon
                              >
                              <span class="gold-text">
                                {{ wine.price_glass }}{{ client.currency }}
                                <template v-if="conversionRate">
                                  <br />
                                  {{ calculatePrice(wine.price_glass)
                                  }}{{ "kn" }}
                                </template>
                              </span>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </transition>
                </slide>
              </carousel-3d>
            </div>
          </v-container>
          <v-container fluid class="footer py-3" v-if="!list && !sommelier">
            <carousel-3d
              v-if="!saved"
              :controls-visible="false"
              :clickable="true"
              :autoplay="false"
              :perspective="0"
              :display="7"
              :scale="5"
              :space="300"
              border="0"
              height="60"
              ref="carouselWine"
              @after-slide-change="
                addFiltersFoodCategory(getAvailableFilters(2), 2, $event + 1)
              "
              class="text-center"
            >
              <template v-if="showHasGlass">
                <slide
                  v-for="(cat, index) in [
                    { title: $t('on_glass') },
                    ...getAvailableFilters(2),
                  ]"
                  :key="index"
                  :index="parseInt(index)"
                  class="slide-cell-2"
                  style="height: 40px !important"
                >
                  <transition fade>
                    <div class="">
                      <div class="">
                        <h3
                          v-if="cat.title.length < 20"
                          class="text-carousel-2 w-100 text-center"
                        >
                          {{ cat.title }}
                        </h3>
                        <h4 v-else class="text-carousel-3 w-100 text-center">
                          {{ cat.title }}
                        </h4>
                      </div>
                    </div>
                  </transition>
                </slide>
              </template>
              <template v-else>
                <slide
                  v-for="(cat, index) in [...getAvailableFilters(2)]"
                  :key="index"
                  :index="parseInt(index)"
                  class="slide-cell-2"
                  style="height: 40px !important"
                >
                  <transition fade>
                    <div class="">
                      <div class="">
                        <h3
                          v-if="cat.title.length < 20"
                          class="text-carousel-2 w-100 text-center"
                        >
                          {{ cat.title }}
                        </h3>
                        <h4 v-else class="text-carousel-3 w-100 text-center">
                          {{ cat.title }}
                        </h4>
                      </div>
                    </div>
                  </transition>
                </slide>
              </template>
            </carousel-3d>
          </v-container>

          <v-container
            class="d-flex justify-center align-center wines-4"
            v-if="
              wines.length == 0 ||
              (saved && this.$store.getters.favs.length == 0)
            "
          >
            <h2 class="mb-0 w-100 text-center white--text">
              {{ $t("products") }}
            </h2>
          </v-container>

          <v-dialog
            v-model="bottleDialog"
            v-if="selectedBottle"
            width="100%"
            heigth="100%"
            @click:outside="bottleDialog = false"
            style="z-index: 999 !important"
          >
            <div class="details-view wines-details position-relative pa-5">
              <div class="d-flex justify-space-between w-100 dialog-button">
                <v-btn
                  class="mx-2"
                  tile
                  depressed
                  fab
                  large
                  color="transparent"
                  v-if="client.qr_orders_active"
                  @click.stop="addToFav(selectedBottle)"
                >
                  <v-icon
                    dark
                    color="rgba(0, 0, 0, .6)"
                    v-if="isFav(selectedBottle.id)"
                  >
                    mdi-check
                  </v-icon>
                  <v-icon dark color="rgba(0, 0, 0, .6)" v-else>
                    mdi-checkbox-marked
                  </v-icon>
                </v-btn>
                <div></div>
                <v-btn
                  class="ma-2"
                  tile
                  depressed
                  large
                  color="#d7c194"
                  @click="closeBottle"
                >
                  {{ $t("close") }}
                  <v-icon dark color="#000" class="ml-3">mdi-close</v-icon>
                </v-btn>
              </div>
              <v-row class="w-100 d-flex dialog-row">
                <v-col
                  cols="12"
                  md="4"
                  class="d-flex justify-center align-center"
                >
                  <div class="d-flex align-end">
                    <v-row class="fill-height" justify="center">
                      <v-col cols="7" class="text-center px-0">
                        <img
                          :src="selectedBottle.image_url"
                          class="dialogBottle"
                          :alt="selectedBottle.title"
                        />
                      </v-col>
                      <v-col cols="5" class="px-0">
                        <v-row class="fill-height" justify="center">
                          <v-col cols="12" class="pl-0">
                            <template
                              v-for="(
                                filter, k, j
                              ) in selectedBottle.grouped_filters"
                            >
                              <div :key="j" v-if="k === '36'">
                                <h2 class="text-center mb-2">
                                  {{ $store.getters.getFilterCatById(k).title }}
                                </h2>
                                <template
                                  v-for="(
                                    item, key, i
                                  ) in selectedBottle.grouped_filters"
                                >
                                  <div
                                    :key="i"
                                    v-if="key === k"
                                    style="display: flex"
                                    class="text-center justify-center"
                                  >
                                    <div
                                      v-for="(value, index) in item"
                                      :key="index"
                                      class="text-center justify-center"
                                      style="
                                        width: 55px;
                                        padding: 2px 2px 2px 2px;
                                      "
                                    >
                                      <img
                                        height="40"
                                        :src="
                                          $store.getters.getFilterById(
                                            key,
                                            value
                                          ).image_url
                                        "
                                      />
                                      <br />
                                      <p
                                        style="
                                          font-size: 10px;
                                          line-height: normal;
                                        "
                                      >
                                        {{
                                          $store.getters.getFilterById(
                                            key,
                                            value
                                          ).title
                                        }}
                                      </p>
                                    </div>
                                  </div>
                                  <!--                            <v-row :key="i" v-if="key === '36'">-->
                                  <!--                              -->
                                  <!--                                <v-col cols="4" v-for="(value, index) in item" :key="index" class="text-center justify-center pa-1">-->
                                  <!--                                  <img height="40" :src=" $store.getters.getFilterById(key, value).image_url "/>-->
                                  <!--                                  <br>-->
                                  <!--                                  <p style="font-size: 10px; line-height: normal;">{{$store.getters.getFilterById(key, value).title}}</p>-->
                                  <!--                                </v-col>-->
                                  <!--                            </v-row>-->
                                </template>
                              </div>
                            </template>
                          </v-col>
                          <v-col
                            cols="12"
                            class="d-flex align-end text-center justify-center pl-0"
                          >
                            <div
                              v-if="selectedBottle.glass"
                              class="text-center"
                            >
                              <img
                                v-if="selectedBottle.glass.image_url"
                                :src="selectedBottle.glass.image_url"
                                class="dialogGlass"
                                :alt="selectedBottle.title"
                              />
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="12" md="8" class="d-flex flex-column">
                  <v-row
                    class="d-flex align-center justify-center justify-md-start"
                  >
                    <v-col class="d-flex" cols="7" lg="5">
                      <h1
                        v-if="selectedBottle.title.length < 30"
                        class="font-weight-bold text-center text-md-left"
                      >
                        {{ selectedBottle.title }}
                      </h1>
                      <h2
                        v-else
                        class="font-weight-bold text-center text-md-left"
                      >
                        {{ selectedBottle.title }}
                      </h2>
                    </v-col>
                    <v-col cols="4">
                      <v-img
                        v-if="selectedBottle.winery.image_url"
                        :src="selectedBottle.winery.image_url"
                        max-height="70px"
                        class="ml-6 pa-4"
                        contain
                      >
                      </v-img>
                    </v-col>
                  </v-row>
                  <v-row v-if="selectedBottle.description" class="flex-grow-0">
                    <v-col cols="12" md="10" class="py-0">
                      <p class="text--secondary ma-0 w-100">
                        {{ selectedBottle.description }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="w-100">
                    <v-col
                      cols="6"
                      md="4"
                      class="d-flex justify-center align-center flex-column pb-0"
                    >
                      <h3 class="text--disabled w-100 mb-1">
                        {{ $t("winery") }}
                      </h3>
                      <h3
                        v-if="selectedBottle.winery"
                        class="font-weight-bold w-100"
                      >
                        {{ selectedBottle.winery.name }}
                      </h3>
                    </v-col>
                    <v-col
                      cols="6"
                      md="4"
                      class="d-flex justify-center align-center flex-column pb-0"
                    >
                      <h3 class="text--disabled w-100 mb-1">
                        {{ $t("region") }}
                      </h3>
                      <h3
                        v-if="
                          selectedBottle.winery && selectedBottle.winery.region
                        "
                        class="font-weight-bold w-100"
                      >
                        {{ selectedBottle.winery.region.name }}
                      </h3>
                    </v-col>
                    <v-col
                      cols="6"
                      md="4"
                      class="d-flex justify-center align-center flex-column pb-0"
                    >
                      <h3 class="text--disabled w-100 mb-1">
                        {{ $t("alcohol") }}
                      </h3>
                      <h3 class="font-weight-bold w-100">
                        {{ selectedBottle.alcohol_percent }}%
                      </h3>
                    </v-col>
                    <template v-if="selectedBottle.grouped_filters">
                      <v-col
                        cols="6"
                        md="4"
                        class="d-flex justify-center align-center flex-column pb-0"
                        v-for="(
                          item, key, index
                        ) in selectedBottle.grouped_filters"
                        :key="index"
                      >
                        <template
                          v-if="
                            key !== '30' &&
                            key !== '35' &&
                            key !== '36' &&
                            key !== '10' &&
                            key !== '4'
                          "
                        >
                          <h3 class="text--disabled w-100 mb-1">
                            {{ $store.getters.getFilterCatById(key).title }}
                          </h3>
                          <h3 class="font-weight-bold w-100">
                            <span
                              v-for="(value, index) in item"
                              :key="value.id"
                            >
                              <!--                                          <template v-if="value !== 448 && value !== 449 && value !== 450 && value !== 451">-->
                              {{ $store.getters.getFilterById(key, value).title}}
                              <template v-if="index < item.length - 1">,</template>
                              <!--                                        </template>-->
                            </span>
                          </h3>
                        </template>
                        <template v-else-if="key === '10'">
                          <h3 class="text--disabled w-100 mb-1">
                            {{ $store.getters.getFilterCatById(key).title }}
                          </h3>
                          <h3 class="font-weight-bold w-100 mb-1">
                            <v-chip
                              v-for="(value) in item"
                              :key="value.id"
                              outlined
                              color="#D7C194"
                              style="color: black; border-radius: 0"
                              class="ma-1"
                            >
                              <!--                                          <template v-if="value !== 448 && value !== 449 && value !== 450 && value !== 451">-->
                              {{ $store.getters.getFilterById(key, value).title
                              }}
<!--                              <template v-if="index < item.length - 1"-->
<!--                                >,</template-->
<!--                              >-->
                              <!--                                        </template>-->
                            </v-chip>
                          </h3>
                          <h3 class="w-100" v-if="selectedBottle.combinations && selectedBottle.combinations.length>0">
                            <combined-products-dialog
                              :products="
                                getProductsByIds(selectedBottle.combinations)
                              "
                            ></combined-products-dialog>
                          </h3>
                        </template>
                      </v-col>
                    </template>
                  </v-row>
                  <div
                    class="prices d-flex flex-column flex-md-row py-6 w-md-50 justify-md-space-between"
                  >
                    <template v-if="selectedBottle.price_magnum">
                      <div class="d-flex align-center">
                        <v-icon color="#800020" size="56"
                          >mdi-bottle-wine</v-icon
                        >
                        <span class="burgundy-text font-weight-bold"
                          >{{ selectedBottle.price_magnum
                          }}{{ client.currency }}
                          <template v-if="conversionRate">
                            <br />
                            {{ calculatePrice(selectedBottle.price_magnum)
                            }}{{ "kn" }}
                          </template>
                        </span>
                      </div>
                    </template>
                    <template v-if="selectedBottle.price_bottle">
                      <div class="d-flex align-center">
                        <v-icon color="#800020" size="46"
                          >mdi-bottle-wine</v-icon
                        >
                        <span class="burgundy-text font-weight-bold"
                          >{{ selectedBottle.price_bottle }}{{ client.currency }}
                            <template v-if="selectedBottle.amount">
                                {{ ' / ' + selectedBottle.amount + 'l'}}
                            </template>

                          <template v-if="conversionRate">
                            <br />
                            {{ calculatePrice(selectedBottle.price_bottle)
                            }}{{ "kn" }}
                              <template v-if="selectedBottle.amount">
                                {{ ' / ' + selectedBottle.amount + 'l'}}
                            </template>
                          </template>
                        </span>
                      </div>
                    </template>
                    <template v-if="selectedBottle.price_glass">
                      <div class="d-flex align-center mt-6 mt-md-0">
                        <v-icon color="#800020" size="36"
                          >mdi-glass-wine</v-icon
                        >
                        <span class="burgundy-text font-weight-bold">
                          {{ selectedBottle.price_glass }}{{ client.currency }}
                            <template v-if="selectedBottle.pivot_amount">
                                {{ ' / ' + selectedBottle.pivot_amount + 'l'}}
                            </template>
                          <template v-if="conversionRate">
                            <br />
                            {{ calculatePrice(selectedBottle.price_glass)
                            }}{{ "kn" }}
                              <template v-if="selectedBottle.pivot_amount">
                                {{ ' / ' + selectedBottle.pivot_amount + 'l'}}
                            </template>
                          </template>
                        </span>
                      </div>
                    </template>
                  </div>
                </v-col>
              </v-row>
              <v-row
                align="center"
                style="position: absolute; bottom: 20px; right: 20px"
                v-if="
                  selectedBottle.logo_text ||
                  selectedBottle.logo ||
                  client.sponsor_text ||
                  client.sponsor_logo
                "
              >
                <v-col cols="12" class="py-0 pr-0" fill-height>
                  <div style="align-items: center; display: flex">
                    <span v-if="selectedBottle.logo_text" class="mr-4">{{
                      selectedBottle.logo_text
                    }}</span>
                    <span v-else-if="client.sponsor_text" class="mr-4">{{
                      client.sponsor_text
                    }}</span>
                    <img
                      v-if="selectedBottle.logo"
                      height="70px"
                      :src="selectedBottle.logo"
                    />
                    <img
                      v-else-if="client.sponsor_logo"
                      height="70px"
                      :src="client.sponsor_logo"
                    />
                  </div>
                </v-col>
              </v-row>
              <v-img
                v-if="selectedBottle.winery.region.image_url"
                :src="selectedBottle.winery.region.image_url"
                class="vina-cover z-index-0"
              ></v-img>
              <v-img
                v-else-if="
                  $store.getters.getCountryById(selectedBottle.country_id)
                    .image_url
                "
                :src="
                  $store.getters.getCountryById(selectedBottle.country_id)
                    .image_url
                "
                class="vina-cover z-index-0"
              ></v-img>
              <div class="vina-cover overlay"></div>
            </div>
          </v-dialog>
          <v-dialog v-model="orderDialog" class="order-dialog" max-width="500">
            <div class="pa-4">
              <v-form @submit.prevent="submitOrder" class="d-flex flex-column">
                <v-text-field
                  v-model="tableNo"
                  :label="$t('table_number')"
                  color="black"
                  required
                ></v-text-field>
                <v-textarea
                  v-model="tableNo"
                  :label="$t('note')"
                  color="black"
                ></v-textarea>
                <v-btn
                  color="#d7c194"
                  depressed
                  class="main-btn mr-5 mb-1 align-self-end"
                  height="56"
                  type="submit"
                >
                  {{ $t("order") }}
                </v-btn>
              </v-form>
            </div>
          </v-dialog>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ListItem from "../components/ListItem";
import GridItem from "../components/GridItem";
import UiTiles from "../assets/img/UiTiles";
import GenList from "../assets/img/GenList";
import UiCover from "../assets/img/UiCover";

import Swiper from "swiper/bundle";
import "swiper/swiper-bundle.css";
import CombinedProductsDialog from "../components/CombinedProductsDialog";

export default {
  name: "Wines",
  components: {
    CombinedProductsDialog,
    "list-item": ListItem,
    "grid-item": GridItem,
    "ui-tiles": UiTiles,
    "ui-cover": UiCover,
    "gen-list": GenList,
  },
  computed: {
    showHasGlass(){
      let hasGlass = false;
      if (this.selectedCountry) {
        hasGlass = this.allWines.filter((wine) => {
          return this.selectedCountry === wine.winery?.region?.country_id;
        }).some((x) => x.price_glass != null);
      }else{
        hasGlass = this.allWines.some((x) => x.price_glass != null);
      }
      return hasGlass;
    },
    allWines() {
      return this.$store.getters.getProductsByName(1);
    },
    client() {
      return this.$store.getters.getClient;
    },
    conversionRate() {
      return this.$store.getters.getClient.dual_price;
    },
    grouped_products() {
      return this.$store.getters.getGroupedProducts;
    },
    filterCategories() {
      return this.$store.getters.getFilters;
    },
    regions() {
      let regions = this.$store.getters.getRegions;
      if (this.selectedCountry)
        return regions.filter(
          (item) => item.country_id == this.selectedCountry
        );
      return regions;
    },

    availableFilters() {
      let available = new Set();
      this.allWines.forEach((item) => {
        item.filter_ids.forEach((id) => {
          available.add(id);
        });
      });
      return available;
    },

    ads() {
      return this.$store.getters.getAds;
    },
    showAds() {
      return this.$store.getters.showAds;
    },
  },
  data() {
    return {
      search: "",
      type_price: 0,
      current: true,
      wines: [],
      glass: false,
      selectedCountry: null,
      selectedRegions: [],
      selectedRegionsModels: {},
      list: false,
      saved: false,
      menu: false,
      menu1: false,
      grid: false,
      cover: true,
      bottleDialog: false,
      time_spent: 0,
      interval: null,
      selectedBottle: null,
      filters: {},
      newFilters: {},
      orderDialog: false,
      tableNo: "",
      sommelier: false,
      //ads
      counter: 0,
      current_ads: null,
      setAdTimeout: null,
      length_ads: 0,
    };
  },
  methods: {
    getProductsByIds(ids) {
      return this.$store.getters.getProductsByClientProductIds(ids)
    },
    calculatePrice(price) {
      return (price / this.conversionRate).toFixed(2).toString();
    },
    addFiltersFoodCategory(foodCategory, catID, e) {
      this.clearFilters();
      let carouselIndex = this.$refs.carouselWine.currentIndex;
      let hasGlass = false;
      if (this.selectedCountry) {
        hasGlass = this.allWines.filter((wine) => {
          return this.selectedCountry === wine.winery?.region?.country_id;
        }).some((x) => x.price_glass != null);
      }else{
        hasGlass = this.allWines.some((x) => x.price_glass != null);
      }
      if (carouselIndex == 0 && hasGlass) {
        this.glass = true;
      } else {
        this.glass = false;
        if (hasGlass) {
          this.toggleFilter(foodCategory[carouselIndex - 1].id, catID, e);
        } else {
          this.toggleFilter(foodCategory[carouselIndex].id, catID, e);
        }
      }
      this.current = false;
      this.filterProducts();
    },
    toggleFilter(filterID, catID, e) {
      if (typeof this.filters[catID] == "undefined") {
        this.filters[catID] = [];
      }

      let catFilter = this.filters[catID];

      if (e) {
        catFilter.push(filterID);
      } else {
        catFilter.splice(catFilter.indexOf(filterID), 1);

        if (catFilter.length == 0) {
          delete this.filters[catID];
        }
      }
    },
    async winesBack() {
      let hash = localStorage.vina_hash;
      if (hash != "undefined") this.$router.push("/" + hash + "");
    },
    openBottle(wine) {
      this.time_spent = 0;
      this.selectedBottle = wine;
      this.bottleDialog = true;
      this.increaseClicks(wine);
      this.startTimer();
    },
    increaseClicks(product) {
      // dispatch action that updates clicks
      this.$store.dispatch("increaseClicks", {
        client_product_id: product.client_product_id,
      });
    },
    increaseTimeSpent(product, time_spent) {
      // dispatch action that updates clicks
      this.$store.dispatch("increaseTimeSpent", {
        client_product_id: product.client_product_id,
        time_spent: time_spent,
      });
    },
    startTimer() {
      this.interval = setInterval(this.incrementTime, 1000);
    },
    incrementTime() {
      if (this.interval != null) {
        this.time_spent = parseInt(this.time_spent) + 1;
      }
    },
    closeBottle() {
      clearInterval(this.interval);
      this.interval = null;
      this.increaseTimeSpent(this.selectedBottle, this.time_spent);
      this.time_spent = 0;
      this.bottleDialog = false;
      this.selectedBottle = null;
    },
    toggleCover(item) {
      if (item) {
        this.search = "";
        this.grid = false;
        this.list = false;
        this.cover = true;
        this.saved = false;
      } else {
        this.cover = !this.cover;
        if (this.cover === true) {
          this.search = "";
          this.grid = false;
          this.list = false;
          this.cover = true;
          this.saved = false;
          localStorage.setItem("wine_grid", 0);
        } else {
          this.grid = false;
          this.cover = false;
          this.list = true;
          this.saved = false;
          localStorage.setItem("wine_grid", 1);
          if (this.$refs.wineDisplay) {
            this.$refs.wineDisplay.scrollTo(0, 0);
          }
        }
      }
    },
    toggleList() {
      this.grid = false;
      this.cover = false;
      this.list = true;
      this.saved = false;
      if (this.$refs.wineDisplay) {
        this.$refs.wineDisplay.scrollTo(0, 0);
      }
    },
    toggleGrid() {
      this.cover = false;
      this.list = false;
      this.grid = true;
      this.saved = false;
      if (this.$refs.wineDisplay) {
        this.$refs.wineDisplay.scrollTo(0, 0);
      }
    },
    toggleSaved() {
      //this.$router.push('/cart');
      this.grid = false;
      this.cover = false;
      this.list = false;
      this.saved = true;
      if (this.$refs.wineDisplay) {
        this.$refs.wineDisplay.scrollTo(0, 0);
      }
    },
    clearFilters(resetProducts = true) {
      this.type_price = 0;
      this.filters = {};
      // Object.keys(this.filters).forEach((key) => key==35 || delete this.filters[key]);
      this.newFilters = {};
      this.selectedRegionsModels = {};
      this.selectedRegions = [];
      this.glass = false;
      if (resetProducts) {
        this.filterProducts();
      }
    },
    filterProducts() {
      let filtered = this.allWines;

      if (this.sommelier) {
        this.clearFilters(false);
        filtered = filtered.filter((item) => {
          return item.sommelier === 1;
        });
      }

      if (this.selectedRegions.length > 0) {
        filtered = filtered.filter((wine) => {
          return this.selectedRegions.includes(wine.winery?.region.id);
        });
      }

      if (this.selectedCountry) {
        filtered = filtered.filter((wine) => {
          return this.selectedCountry === wine.winery?.region?.country_id;
        });
      }

      if (this.glass) {
        filtered = filtered.filter((wine) => {
          return wine.price_glass != null;
        });
      }

      Object.keys(this.filters).forEach((key) => {
        filtered = filtered.filter((wine) => {
          return wine.filter_ids.some((a) => this.filters[key].includes(a));
        });
      });

      if (this.type_price === 1) {
        filtered = filtered.filter((price) => {
          return (
            parseInt(price.price_bottle) > 0 &&
            parseInt(price.price_bottle) <= 100
          );
        });
      } else if (this.type_price === 2) {
        filtered = filtered.filter((price) => {
          return (
            parseInt(price.price_bottle) > 100 &&
            parseInt(price.price_bottle) <= 300
          );
        });
      } else if (this.type_price === 3) {
        filtered = filtered.filter((price) => {
          return parseInt(price.price_bottle) > 300;
        });
      }

      this.wines = filtered;
      var searchQuery = this.search;
      window.dispatchEvent(new Event("resize"));
      if (searchQuery === "") {
        this.wines = filtered;
      } else {
        this.wines = this.wines.filter((post) => {
          return (
            post.title.toLowerCase().includes(this.search.toLowerCase()) ||
            post.winery.name.toLowerCase().includes(this.search.toLowerCase())
          );
        });
      }
    },
    async addToFav(obj) {
      let arr = this.$store.getters.favIds;
      let index = arr.indexOf(obj.id);

      if (index > -1) {
        arr.splice(index, 1);
      } else {
        arr.push(obj.id);
      }
      if (this.isFav(obj.id)) {
        await this.$store.dispatch("removeFromFav", { product_id: obj.id });
      } else {
        await this.$store.dispatch("addToFav", { product_id: obj.id });
      }
    },
    addFilters(country_id, sommelier = false) {
      /*if (country_id === undefined) {
        this.toggleFilter(473, 35, true)
      }*/
      this.current = false;
      this.sommelier = sommelier;
      this.selectedCountry = country_id;
      let hasGlass = this.allWines.some((x) => x.price_glass != null);
      if (!hasGlass) {
        this.toggleFilter(this.getAvailableFilters(2)[0].id, 2, 1);
        // this.glass=false;
      } else {
        // this.glass=true;
      }
      this.filterProducts();
    },
    winesBackToFilters() {
      this.menu = false;
      this.current = true;
      this.selectedCountry = null;
      this.search = "";
      this.clearFilters();
    },
    isFav(id) {
      if (this.$store.getters.favs.some((item) => item.id === id)) {
        return false;
      }
      return true;
    },
    filterRegion(id, e) {
      if (e) {
        this.selectedRegions.push(id);
      } else {
        this.selectedRegions.splice(this.selectedRegions.indexOf(id), 1);
      }
    },
    async initializeSwiper() {
      let self = this;
      this.vinaSwiper = new Swiper("#winesSwiper", {
        slidesPerView: 5,
        initialSlide: 2,
        grabCursor: true,
        longSwipes: false,
        coverflowEffect: {
          rotate: 0,
          depth: 500,
          slideShadows: false,
        },
        virtual: {
          cache: false,
          renderExternal: (data) => {
            this.virtualData = data;
          },
          slides: this.wines,
        },
      });
    },
    getAvailableFilters(id) {
      let availableFilters = this.client?.available_filters[id] ?? [];
      if (typeof availableFilters === "object" && availableFilters !== null) {
        availableFilters = Object.values(availableFilters);
      }
      return availableFilters.filter((x) =>
        this.allWines.some(
          (y) =>
            (this.selectedCountry != null
              ? this.selectedCountry == y.country_id
              : true) && y.filter_ids.includes(x.id)
        )
      );

      // //   return this.availableFilters.has(item.id)
      // // }))
      // var result = items.filter(item => {
      //   return this.availableFilters.has(item.id)
      // })
      //
      // var sortedArray = null
      // if (this.client.client_filters.length !== 0) {
      //   var filters = this.client.client_filters[2]
      //   if (filters !== undefined) {
      //     for (let i = 0; i < result.length; i++) {
      //       var el = result[i]
      //       for (let j = 0; j < filters.length; j++) {
      //         var el2 = filters[j]
      //         if (el.id === el2.id) {
      //           result[i]['sequence'] = el2.sequence
      //           break
      //         } else
      //           el['sequence'] = filters.length + 1
      //       }
      //     }
      //
      //     sortedArray = result.sort((a, b) => (a.sequence > b.sequence) ? 1 : -1)
      //   } else
      //     sortedArray = result
      // } else {
      //   sortedArray = result
      // }
      //
      // return sortedArray
    },
    openOrderDialog() {
      this.orderDialog = true;
    },
    submitOrder() {
      this.orderDialog = false;
    },
    setCurrent() {
      let time = 4000;
      this.length_ads = this.ads?.length;
      if (this.ads?.length > 0) {
        let ad = this.ads[this.counter];
        this.current_ads = ad;
        this.incrementCounter();
        if (!this.current_ads.video_url == false) {
          return;
        } else {
          time = 4000;
        }
      }
      this.setAdTimeout = setTimeout(() => {
        this.setCurrent();
      }, time);
    },
    endVideo() {
      this.setCurrent();
    },
    incrementCounter() {
      if (this.counter === this.ads?.length - 1) {
        this.counter = 0;
      } else {
        this.counter++;
      }
    },
    closeAd() {
      this.$store.dispatch("showAds", false);
      this.startTimeout();
    },
    startTimeout() {
      clearTimeout(this.menuTimeout);
      this.menuTimeout = setTimeout(() => {
        clearTimeout(this.setAdTimeout);
        this.setCurrent();
        // this.$store.commit("clearFavs");
        this.$store.dispatch("showAds", true);
      }, 3 * 60 * 1000);
    },
  },

  watch: {
    search: function () {
      this.filterProducts();
    },

    ads: function (newVal, oldVal) {
      if (oldVal?.length === 0 && newVal?.length > 0) {
        this.startTimeout();
      }
    },
  },

  created() {
    if (localStorage.getItem("wine_grid") != null) {
      if (localStorage.getItem("wine_grid") == 0) {
        this.grid = false;
        this.list = false;
        this.cover = true;
        this.saved = false;
      } else if (localStorage.getItem("wine_grid") == 1) {
        this.grid = false;
        this.cover = false;
        this.list = true;
        this.saved = false;
        if (this.$refs.wineDisplay) {
          this.$refs.wineDisplay.scrollTo(0, 0);
        }
      }
    }
    this.$store.dispatch("getClient", { fromServer: false });
    this.$store.dispatch("getFilters", { fromServer: false });
    this.$store.dispatch("getRegions", { fromServer: false });
    let hasGlass = this.allWines.some((x) => x.price_glass != null);
    if (!hasGlass) {
      this.toggleFilter(this.getAvailableFilters(2)[0].id, 2, 1);
      // this.glass=false;
    } else {
      // this.glass=true;
    }
    this.filterProducts();

    if (this.ads?.length > 0) {
      this.startTimeout();
    }
    document.addEventListener("click", this.startTimeout);
  },
  async mounted() {
    //await this.initializeSwiper();
    //this.vinaSwiper.update();
  },
};
</script>
<style lang="scss" scoped>
.wines {
  min-height: 100vh;
}

.text-carousel-2 {
  color: rgba(255, 255, 255, 0.4);
  font-size: 25px;
  //text-shadow: -1px 0 #ba8c2f, 0 1px #ba8c2f, 1px 0 #ba8c2f, 0 -1px #ba8c2f;
  color: white;
}

.text-carousel-3 {
  color: rgba(255, 255, 255, 0.4);
  font-size: 18px;
  //text-shadow: -1px 0 #ba8c2f, 0 1px #ba8c2f, 1px 0 #ba8c2f, 0 -1px #ba8c2f;
  color: white;
}

.slide-cell-2 {
  min-height: 35vh;
  background: transparent !important;
}

@media (max-width: 768px) {
  .wines-2 {
    margin-top: -50px !important;
  }
}

.wine-link {
  text-decoration: none !important;
}

.vina-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -30%;
  left: 30%;
  height: 100%;
  overflow: hidden;
}

.z-index-0 {
  z-index: 0 !important;
}

.overlay {
  z-index: 1 !important;
  background: linear-gradient(135deg, #f5f6fa 30%, transparent);
}

.dialog-row {
  word-break: break-word;
  display: flex;
}

.dialog-button {
  position: absolute;
  z-index: 3;
  top: 10px;
}

.dialogBottle {
  max-height: 60vh !important;
}

.dialogGlass {
  max-height: 35vh !important;
  align-self: flex-end;
}

.filter-check {
  overflow: visible;
}

.button-wrap {
  position: relative;
  width: 100%;
  height: 100%;
}

.cell-bottle {
  width: auto !important;
  height: 53vh;
  z-index: 4;
  margin-top: 0;
  transition: 0.5s ease;
}

.prices {
  .v-icon {
    //font-size: 46px !important;
  }
}

.winery-logo {
  max-height: 50px;
}

.bacve-cover {
  height: 60vh;
  position: fixed;
  top: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0.3)),
    url("../assets/img/bacve.png");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}

@keyframes fadeIn {
  from {
    opacity: 0%;
  }

  to {
    opacity: 100%;
  }
}

.menu-item1 {
  width: 100%;
  height: 16vh;
  background-color: #191c21;
  border: 1px solid #272c33;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  * {
    transition: 0.3s ease;
  }

  p {
    color: rgba(255, 255, 255, 0.4);
    font-size: 25px;
  }

  &:hover {
    * {
      color: #fff !important;
      transition: 0.3s ease;
    }
  }
}

.menu-item2 {
  width: 100%;
  height: 16vh;
  background-color: #191c21;
  border: 1px solid #a4906a;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  * {
    transition: 0.3s ease;
  }

  p {
    color: rgba(255, 255, 255, 0.4);
    font-size: 25px;
  }

  &:hover {
    * {
      color: #fff !important;
      transition: 0.3s ease;
    }
  }
}

.client {
  min-height: 100vh;
  position: relative;
}

.home-2 {
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-carousel {
  font-size: 40px;
  //text-shadow: -1px 0 #ba8c2f, 0 1px #ba8c2f, 1px 0 #ba8c2f, 0 -1px #ba8c2f;
  color: #d7c194;
}

.text-carousel-vine {
  font-size: 30px;
  color: #d7c194;
}

.carousel-wrap2 {
  height: 90%;
  transition: 0.6s ease;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.child {
  display: table-cell;
}

.childinner {
  margin-left: 25px;
}

.child:first-child .childinner {
  margin-left: 0;
}

.swiper-slide-active {
  .carousel-div {
    z-index: -1;
  }
}

.current,
.swiper-slide-active {
  .carousel-div,
  .carousel-text2 {
    opacity: 1 !important;
    display: flex !important;
    margin-top: 0px !important;
  }

  .image-wrap {
    .cell-bottle {
      transition: 0.5s ease;
      margin-bottom: 0;
    }
  }
}

.carousel-text2 {
  z-index: 2;
  opacity: 1 !important;
  align-items: center;
  display: flex;
  flex-direction: column;
  transition: 0.6s ease;
  margin-top: 120px !important;
}

.custom-field {
  width: 170px;
}

.wines-details {
  min-height: 90vh;
}

.order-dialog {
  width: 60% !important;
}

.grid-col {
  margin-bottom: 200px;
}

.v-badge__badge .white {
  color: black !important;
}
</style>
